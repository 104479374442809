.color-00 {
  background-color: white;
}

.landing__section {
  padding: 3vw 0vw;
}
.landing__section-title {
  color: #4c399f;
  font-weight: bolder;
  font-size: 30pt;
}

.landing__section-first-form {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.landing__section-button {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  flex-direction: row;
}

.landing__section-largeimage {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.landing__section-image {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}

.landing__section-text {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: center;
}

.right-text {
  text-align: right;
}

@media only screen and (max-width: 850px) {
  .landing__section-image {
    width: 75vw;
  }
  .landing__section {
    padding: 5vw 0vw;
  }
}

@media only screen and (max-width: 1204px) and (min-width: 850px) {
  .landing__section-image {
    width: 35vw;
    object-fit: contain;
  }
}
