.header {
  z-index: 999;
  position: fixed;
  background-color: #f4f5fa;
  width: 100%;
  box-shadow: -1px 7px 13px -7px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: -1px 7px 13px -7px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -1px 7px 13px -7px rgba(0, 0, 0, 0.1);
}

.header__container {
  background-color: #f4f5fa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5vw;
}

.header__image {
  height: 40px;
  margin: 15px 0px;
}

.header__button-section {
  display: flex;
  justify-content: flex-end;
}

.header__button-container {
  display: flex;
  align-items: center;
}

.header__button-separator {
  margin: 0vw 0.3vw;
}

.header__separator {
  height: 75px;
}

@media (max-width: 500px) {
  .header {
    position: static;
  }
  .header__container {
    flex-direction: column;
  }
  .header__separator {
    display: none;
  }

  .header__button-container {
    margin-bottom: 1rem;
  }
}

@media (max-width: 767px) {
  .header__link {
    display: none;
  }
}
