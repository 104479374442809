/* disabled = #686670 */

.tier-card,
.tier-card-selected,
.tier-card-disabled {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  border: 1px solid #4c399f;
  border-radius: 10px;
  width: 100%;
  height: 17rem;
  margin: 1rem 0.5rem;
  overflow: hidden;
}

.tier-card-selected {
  box-shadow: 0 0 20px #3c4b64;
  border: 1px solid #3c4b64;
}

.tier-card-disabled {
  border: 1px solid #a198cd;
  color: #7e8898;
}

.tier-card__container,
.tier-card__container-disabled,
.tier-card__container-selected {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 0.2rem;
}

.tier-card__container {
  background-color: #4c399f;
  color: white;
}

.tier-card__container-selected {
  background-color: #3c4b64;
  color: white;
}

.tier-card__container-disabled {
  background-color: #a198cd;
  color: white;
}

.tier-card__title {
  font-size: large;
}

.tier-card__price-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tier-card__price-container__title {
  font-size: xx-large;
}

.tier-card__button,
.tier-card__button-disabled,
.tier-card__button-selected {
  width: 100%;
  border: none;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.tier-card__button {
  background-color: #4c399f;
  color: white;
}

.tier-card__button-selected {
  background-color: #3c4b64;
  color: white;
}

.tier-card__button-disabled {
  background-color: #a198cd;
  color: white;
}

.checkout__checkbox,
.checkout__checkbox-error {
  appearance: none;
  width: 1rem;
  height: 1rem;
  margin-right: 1rem;
  border: 1px solid #4c399f;
  border-radius: 4px;
  outline: none;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  background-color: inherit;
  cursor: pointer;
}

.checkout__checkbox-error {
  border-color: red;
}

.checkout__checkbox:checked {
  background-color: #4c399f;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0, 43% 62%);
}
