.footer {
  background-color: #3c4b64;
}

.footer__container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer__gral-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__image {
  height: 40px;
  margin-top: 10px;
  margin-bottom: 5px;
}

p.footer__text {
  margin: 3px 0;
}

.footer__text-link p {
  margin: 3px;
}

.footer__text {
  color: #7e8898;
}

.footer__text a {
  color: #7e8898;
}

.footer__text-link {
  color: rgba(255, 255, 255, 0.952);
}

.footer__text-link:hover {
  color: rgba(255, 255, 255, 0.952);
}

.footer__links-line {
  margin-bottom: 15px !important;
}
