.input__text-input {
  border: 1px solid #00000000;
  background-color: #31313110;
  padding: 9px 18px;
  border-radius: 10px;
}

.input__text-input:focus {
  outline: none;
}

.input__text-label {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 12pt;
}

.input__text-input-container {
  min-width: 170px;
  margin: 1rem 0%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.input__text-input-error {
  background-color: rgba(221, 0, 0, 0.089);
  border: 1px solid rgba(221, 0, 0, 0.541);
}

.input__password-validation-container {
  margin-top: 5px;
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.input__password-validation-popover {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
}

.password-validation-popover__item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#togglePassword {
  margin-left: -35px;
  cursor: pointer;
}

.input__phone-input {
  border: 1px solid #00000000;
  background-color: #31313110;
  padding: 7.5px 18px;
  border-radius: 10px;
  min-width: 120px;
}

.input__phone-input > input {
  width: 10px;
  border: none;
  border-bottom: 1px solid #31313155;
  background-color: transparent;
  /* border-radius: 10px; */
  min-width: 120px;
}

.input__phone-input > input:focus {
  outline: none;
}
