.docs__sidebar__item {
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
}

.docs__sidebar__item:hover {
  background: #ddd;
}

.docs__section-title {
  color: inherit;
  text-decoration: none;
}

.docs__section-title:hover {
  color: inherit;
  border-bottom: 1px solid #212529;
}

.docs__section-bar__title {
  display: flex;
}

.linked-heading_target {
  display: block;
  margin-top: -20px;
  padding-top: 20px;
  visibility: hidden;
  position: absolute;
  margin-top: -175px;
  padding-top: 175px;
}

.docs__link {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

.docs__link:hover {
  color: inherit;
  opacity: 0.9;
  text-decoration: underline;
}

.docs__sidebar-item {
  background-color: #3c4b64;
}

.docs__sidebar-item:hover {
  opacity: 0.9;
}

.docs__sidebar-item-container {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
}

.docs__image-container {
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
}

.docs__image {
  max-width: 80%;
  transition: transform 300ms;
  margin-top: 2rem;
  margin-bottom: 1rem;
  border-radius: 10px;
  box-shadow: -11px 5px 70px -21px rgba(76, 57, 159, 0.15);
  -webkit-box-shadow: -11px 5px 70px -21px rgba(76, 57, 159, 0.15);
  -moz-box-shadow: -11px 5px 70px -21px rgba(76, 57, 159, 0.15);
  border: 0px solid rgba(76, 57, 159, 0);
  cursor: zoom-in;
}

@media (max-width: 1199px) {
  .docs__image.opened {
    max-width: 100% !important;
  }
}

.docs__image:hover {
  transform: scale(1.05);
}

.docs__image.opened {
  background-color: #f7f6fb00;
  cursor: zoom-out;
  object-fit: contain;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  animation-duration: 0.2s;
  animation-name: slidein;
}

.docs__image.opened:hover {
  transform: none;
}

@keyframes slidein {
  from {
    transform: scale(0.3);
  }

  to {
    transform: scale(1);
  }
}

.docs__image-container.opened {
  z-index: 99999;
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  background-color: #f7f6fbf3;
  height: 100%;
}

.docs__button:hover {
  background-color: #4c399f;
}

.docs__index {
  top: 110px;
  position: sticky;
  height: 100vh;
}

@media (min-width: 1800px) {
  .docs__index {
    max-width: 18rem;
  }
}
